<template>
  <div>

    <transition
        mode="out-in"
        enter-class="enter-start-position"
        enter-to-class="enter-end-position"
        enter-active-class="while-enter"
        leave-class="enter-end-position"
        leave-to-class="leave-end-position"
        leave-active-class="while-enter"
    >
      <div v-if="viewProviders" class="games__providers_menu">
        <div class="games__providers_menu__header">
          <button class="games__providers_menu__header__back" @click="closeProviders">
            <img src="../../../assets/icons/index/arrow2.svg"/>
          </button>
          <p class="games__providers_menu__header__title">{{ $t('index.providers') }}</p>
        </div>
        <div class="providers_menu">
          <div v-for="{label, image, key} in providers" :key="key"
               :class="['providers_menu__item', {select: key === provider }]"
               @click="selectProvider(key)">
            <div class="providers_menu__logo">
              <img :src="image"/>
            </div>
            <div class="providers_menu__title">{{ label }}</div>
            <img class="providers_menu__arrow" src="../../../assets/icons/index/providers_arrow.svg"/>
          </div>
        </div>
      </div>
    </transition>

    <div class="games__filter1">
      <div :class="['games__search_form',  { select: search !== '' }]">
        <input :class="['games__search',{ select: search !== '' }]" type="text"
               :placeholder="$t('index.searchPlaceholder')"
               @input="updateSearch"
               :value="search">
      </div>
      <button
          :class="['games__providers', { select: provider !== 'all' }]"
          @click="toggleProviders">
        {{ provider === 'all' ? $t('index.providers') : providerTitle(provider) }}
        <img v-if="provider !== 'all'"
             src="../../../assets/icons/index/cross.svg"/>
        <img v-if="provider === 'all'"
             src="../../../assets/icons/index/providers_arrow.svg"/>
      </button>
    </div>

    <div class="games__filter2">

      <button :class="['games__type', {active: category === 'lotoclub'}]" @click="setCategory('lotoclub')">
        <img src="../../../assets/icons/index/lottery.svg">
        <p>{{ $t('index.lotoClub') }}</p>
      </button>
      <button :class="['games__type', {active: category === 'slots'}]" @click="setCategory('slots')">
        <img src="../../../assets/icons/index/slot-machine.svg">
        <p>{{ $t('index.slots') }}</p>
      </button>
      <button :class="['games__type', {active: category === 'roulette'}]" @click="setCategory('roulette')">
        <img src="../../../assets/icons/index/roulette.svg">
        <p>{{ $t('index.roulette') }}</p>
      </button>
      <button :class="['games__type', {active: category === 'minigames'}]" @click="setCategory('minigames')">
        <img src="../../../assets/icons/index/poker-cards.svg">
        <p>{{ $t('index.minigames') }}</p>
      </button>
      <button :class="['games__type', {active: category === 'sport'}]" @click="setCategory('sport')">
        <img src="../../../assets/icons/index/sport.svg">
        <p>{{ $t('index.sport') }}</p>
      </button>
      <button :class="['games__type', {active: category === 'favorite'}]" @click="setCategory('favorite')">
        <img src="../../../assets/icons/index/favorite.svg">
        <p>{{ $t('index.favorite') }}</p>
      </button>
    </div>
  </div>

</template>

<script>

import {providersIcons, titleDict, providerTitle} from "./gamesConfig";

export default {
  name: "FilterForm",
  props: {
    search: {
      type: String
    },
    provider: {
      type: String
    },
    category: {
      type: String
    },
    setProvider: {
      type: Function
    },
    setCategory: {
      type: Function
    },
    providersList: {
      type: Array
    },
    setSearch: {
      type: Function
    }
  },
  computed: {
    providers() {
      const forDisplay = [];
      this.providersList.forEach(name => {
        forDisplay.push({
          key: name,
          label: providerTitle(name),
          image: providersIcons[name] || ''
        })
      });
      forDisplay.sort((a, b) => a.label < b.label ? -1 : 1)
      return forDisplay
    }
  },
  data() {
    return {
      viewProviders: false,
      providersIcons,
      titleDict,
      providerTitle
    }
  },
  methods: {
    updateSearch(e) {
      this.setSearch(e.target.value);
    },

    selectProvider(provider) {
      this.setProvider(provider)
      this.viewProviders = false
    },

    toggleProviders() {
      if (this.provider !== 'all') {
        this.setProvider('all');
      } else {
        this.viewProviders = true
      }
    },

    closeProviders() {
      this.viewProviders = false
    },
  }
}
</script>

<style lang="scss" scoped>
.games__providers_menu {
  overflow: scroll;
  left: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  background: #2C2C4D;

  .games__providers_menu__header {
    margin-top: 26px;

    .games__providers_menu__header__back {
      top: 20px;
      left: 16px;
      position: absolute;
      padding: 0;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .games__providers_menu__header__title {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #fff;
    }

  }

  .providers_menu {
    margin-top: 26px;

    &__item {
      &.select {
        color: #fff;
        background: #FE5C07;
      }

      display: flex;
      align-items: center;
      height: 54px;
      margin: 0 20px 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 6px;
    }

    &__logo {
      height: 30px;
      width: 30px;
      background: #fff;
      border-radius: 50%;
      margin-left: 16px;
      position: relative;
      overflow: hidden;

      img {
        box-sizing: border-box;
        position: absolute;
        height: 30px;
      }
    }

    &__title {
      margin-left: 8px;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
    }

    &__arrow {
      margin-left: auto;
      margin-right: 16px;
    }
  }
}

.games__filter1 {
  margin-top: 54px;
  display: flex;

  .games__search_form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    width: calc(50% - 2.5px);
    margin: 0 2.5px 0 0;

    &.select {
      background: #FE5C07;
    }
  }

  .games__search {
    &.select {
      color: #fff;
    }

    box-sizing: border-box;
    width: 100%;
    padding: 6px 24px 6px 12px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #9090A3;

    background-image: url('../../../assets/icons/index/search.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 4px);
  }

  .games__providers {
    &.select {
      color: #fff;
      background: #FE5C07;
    }

    position: relative;
    width: calc(50% - 2.5px);
    margin: 0 0 0 2.5px;
    padding: 6px 8px 6px 12px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #9090A3;

    img {
      position: absolute;
      right: 8px;
    }
  }
}

.games__filter2 {
  margin: 16px 0 0;
  display: flex;

  .games__type {
    padding: 0;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.1);
    height: 64px;
    width: calc((100% - 18px) / 4);
    margin-right: 6px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #fff;

    &:last-child {
      margin-right: 0;
    }

    p {
      margin-top: 4px;
    }

    &.active {
      background: #FE5C07;
      background-image: url('../../../assets/icons/index/cross.svg');
      background-size: 20px;
      background-position: top right;
      background-repeat: no-repeat;
    }

  }
}
</style>
